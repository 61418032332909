import React from "react"

export default function JobFormField(props) {
  const name = props.name
  const label = props.label

  return (
    <div className={name + "-field job-form-field"}>
      <label htmlFor={name}>{label}</label>
      <input type="text" id={name} value={props.value} 
      onChange={(updatedText) => props.onChangeFunction(updatedText, props.updateStateFunction, props.storeName)}  />
    </div>
  )
}
