import React from "react"
import { getFullNameFromFirstAndLastNames } from "../../scripts/helpers"
import { getContactsByRole } from "../../scripts/shortlist_helpers"

export default function RoleList(props) {
  //Remove roles from the list if they have no people assigned to them
  const roles = props.roles.filter(
    role => getContactsByRole(role).length > 0
  )

  return (
    <div className="role-list-container">
      <h2>Roles</h2>
          <div className="role-summaries">

            {roles.map(( role ) => (
              <div className="role-list" key={role}>
                  <h3>{role}</h3>

                  {getContactsByRole(role).map(( contactInRole ) => (
                      <h4 key={contactInRole.firstname + '-' + contactInRole.lastname}>
                        {getFullNameFromFirstAndLastNames(contactInRole.firstname, contactInRole.lastname)}</h4>
                    ))
                  }

                </div>
              ))
            }

          </div>
    </div>
  )
}
