import React from "react"
import { allContactsInShortlistHaveRole, storeHasNoRoles } from "../../scripts/shortlist_helpers"
import { submitShortlistToHubspot } from "../../scripts/hubspot_helpers"
import { sendEmail } from "../../scripts/email_helpers"
import EmailStatus from "../../components/EmailStatus"
import { FaAddressCard } from "react-icons/fa";
import { VscLoading } from "react-icons/vsc";
import swal from 'sweetalert'
import validator from 'validator';


const allStringInputsSanitised = (jobTitle, company, message, emails, submitterName) => {

  if (!emails.every((email) => (validator.isEmail(email)) )) {
    return false
  }

  //Optional Field
  // if (company && !validator.isAscii(company)) {
  //   return false
  // } 

  // const alphanumericInputs = [jobTitle, message, submitterName]
  // if (!alphanumericInputs.every((input) => validator.isAscii(input))) {
  //   return false
  // }

  return true
}

const validateForm = (shortlist, allImagesData,
  jobTitle, message, submitterName, company, roles, emails, footerLogo, updateEmailSubmitted, updateHubspotSubmitted) => {

    swal({
      text: 'Send shortlist?',
      buttons: [true, 'Submit']
    })
    .then((confirmSubmit) => {
      if (confirmSubmit) {

        //sanitise inputs
        if (allStringInputsSanitised(jobTitle, company, message, emails, submitterName)) {
          sendEmail(shortlist, allImagesData,
            jobTitle, message, submitterName, company, roles, emails, footerLogo, updateEmailSubmitted)
  
          submitShortlistToHubspot(shortlist, jobTitle, company, message, roles, emails, updateHubspotSubmitted)
        }
        else {
          swal({
            text: 'There was an issue with a form field, potentially due to a missing field, invalid email address, or special characters. Please try again or contact us.',
            button: 'Okay'
          })
        }
        
      }
    })
  }


export default function SubmitShortlist(props) {
    const shortlist = props.shortlist
    const allImagesData = props.allImagesData
    const verified = props.verified

    const [emailSubmitted, updateEmailSubmitted] = React.useState("DEFAULT")
    const [hubspotSubmitted, updateHubspotSubmitted] = React.useState("DEFAULT") // Need to decide how to react to an error status here

    const roles = props.roles.sort((a, b) => a.localeCompare(b)) //alphabetise


    if (emailSubmitted === "ERROR") {
      return (
        <div className="form-status">
            <p>There was an issue with your submission. Please try again or contact us directly via email.</p>
        </div>
      )
    }
    else if (emailSubmitted === "PENDING") {
      return (
        <div className="form-status">
          <VscLoading className="spinning-icon" size={40} />
        </div>
      )
    }
    else if (shortlist.length === 0) {
      return (
        <div className="form-status">
            <p>Please add someone to the shortlist to enable submission</p>
        </div>
      )
    }
    else if (!allContactsInShortlistHaveRole() || storeHasNoRoles()) {
      return (
        <div className="form-status">
            <p>Please add a role to all people to enable submission</p>
        </div>
      )
    }
    else if (shortlist.length === 0 || !props.jobTitle || props.emails.length === 0 || roles.length === 0 || !verified) {
      return (
        <div className="form-status">
            <p>Please complete all fields and recaptcha to enable submission</p>
        </div>
      )
    }
    else if (emailSubmitted === "DEFAULT") {
        return (
            <div className="submit-shortlist-button">
                <button className="button" onClick={() => validateForm(shortlist, allImagesData,
                   props.jobTitle, props.message, props.submitterName, props.company, roles, props.emails, 
                   props.footerLogo, updateEmailSubmitted, updateHubspotSubmitted)} >
                  <FaAddressCard size={40} />
                  Send
                </button>
            </div>
        )
    }
    else if (emailSubmitted === "SUCCESS") {
        return (
            <EmailStatus emailStatus={emailSubmitted} hubspotStatus={hubspotSubmitted} />
        )
    }
    else {
      return null
    }

}