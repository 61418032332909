import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import store from "store"
import ShortlistButton from "../Shortlist/ShortlistButton"

//** For some reason this doesn't give correct results when I use the external version of the function */
const contactIsInShortlist = (contactToCheck) => {
  //Check for empty shortlist
  if (!store.get('shortlist')) {
    return false
  }

  let currentShortlist = store.get('shortlist')
  let inShortlist = false

  currentShortlist.forEach((shortlistContact) => {
    if (contactToCheck.id === shortlistContact.id) {
      inShortlist = true
    }
  });

  return inShortlist
}


export default function ProfileTile(props) {

  const contact = props.contactData
  const profileUrl = "/" + contact.fields.slug
  const fullname = contact.fullname
  const altText = fullname + " profile image" 

  let inShortlist = contactIsInShortlist(contact)

  if (!props.tileImage) { //Protect against missing images
    return null
  }

  return (
    <div className="profile-tile-container">

      <ShortlistButton contact={contact} inShortlist={inShortlist} updateShortlist={props.updateShortlist} />

      <Link to={profileUrl} className="profile-tile-content">
        <GatsbyImage image={props.tileImage} alt={altText} className="profile-tile-image-container" 
          imgClassName="profile-tile-image" objectFit="cover" />
        
        <div className="profile-link-container">
          <span className="profile-link">
            {fullname}
          </span>
        </div>

      </Link>
    </div>
  )
  
}
