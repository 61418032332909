import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import ProfileTile from "../components/ProfileGrid/ProfileTile"
import SubmitShortlist from "../components/Shortlist/SubmitShortlist"
import JobFormField from "../components/Shortlist/JobFormField"
import RoleList from "../components/Shortlist/RoleList"
import { getCurrentStoreArray, getContactNotesFromStore,
    addContactRoleToStore, addContactNotesToStore, getStringFromStore,
     addStringToStore, clearShortlist, getStoredContactRole, getCurrentRolesInStore,
      removeInvalidRolesFromContacts, getCurrentShortlist, atLeastOneContactHasAvailableRole, 
      shortlistStoreExpired } from "../scripts/shortlist_helpers"
import { convertArrayToMultiSelectObject, getSlugFromContact, getAgeFromDateOfBirth, getTileImageFromAllImagesData } from "../scripts/helpers"
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import MultiSelect from  'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import store from "store"
import { BsFillPeopleFill } from "react-icons/bs";
import { FaRegTimesCircle } from "react-icons/fa";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import useSSR from 'use-ssr'
import { Helmet } from 'react-helmet'


const onListChange = (newList, updateFunction, storeName, updateRoleSelectKeyFunction) => {
  store.set(storeName, newList)
  updateFunction(newList)

  if (storeName === 'roles') {
    if (getCurrentRolesInStore.length === 0) {
      removeInvalidRolesFromContacts()
      updateRoleSelectKeyFunction(Date.now) //re-render roles dropdowns but updating their key
    }
  }
}

const onContactRoleChange = (updatedRole, contactSlug, updateLastContactRoleFunction, updateShortlistFunction) => {
  addContactRoleToStore(updatedRole, contactSlug)
  updateLastContactRoleFunction(contactSlug) //re-render RoleList by updating this prop
  
  //Refresh shortlist to keep interface in sync
  const updatedShortlistFromStore = getCurrentShortlist()
  updateShortlistFunction(updatedShortlistFromStore)
}

const onTextInputChange = (updatedValue, updateFunction, storeName) => {
  let newStringValue = updatedValue
  if (typeof newStringValue !== 'string' || !(newStringValue instanceof String)) { // If only one value passed in
    newStringValue = updatedValue.target.value
  }

  updateFunction(newStringValue)
  addStringToStore(newStringValue, storeName)
}

const Shortlist = ( {data, pageContext} ) => {

//   const allImagesData = data.images.nodes //Keep all images instead of single image per person, for future image selection feature
  const imagesData = pageContext.tileImagesData

  const footerLogo = data.footer.data.footer_logo
  
  const [shortlist, updateShortlist] = React.useState(getCurrentStoreArray('shortlist'))
  const [emails, updateEmails] = React.useState(getCurrentStoreArray('emails'))
  const [message, updateMessage] = React.useState(getStringFromStore('message'))
  const [jobTitle, updateJobTitle] = React.useState(getStringFromStore('job-title'))
  const [name, updateName] = React.useState(getStringFromStore('name'))
  const [company, updateCompany] = React.useState(getStringFromStore('company'))
  const [roles, updateRoles] = React.useState(getCurrentStoreArray('roles'))

  //Used to pass a new prop to the RoleList to refresh on contact role change
  const [lastContactRole, updateLastContactRole] = React.useState('default')
  const [roleSelectKey, updateRoleSelectKey] = React.useState('default')

  const title = "Shortlist"

  const Recaptcha = require('react-recaptcha')
  // const [verified, updateVerified] = React.useState(false)
  const { isBrowser } = useSSR()

  // const [verified, updateVerified] = React.useState(true) //For testing
  const [verified, updateVerified] = React.useState(false)

  if (shortlistStoreExpired()) {
    clearShortlist(updateShortlist, updateRoles, updateEmails,
      updateMessage, updateJobTitle, updateName, updateCompany, 'EXPIRY')
  }

  const maxAgeToDisplay = 18

  return (
    <Layout>

      <Helmet>
        <title>Shortlist</title>
        <meta name='description' content='Manage and submit a shortlist to request talent for jobs and auditions' />
      </Helmet>

      <div className="shortlist-page">

        <title>{title}</title>

        <div className="page-heading-with-icon">
          <BsFillPeopleFill size={40} />
          <h1>{title}</h1>
        </div>

        <div className="introduction-text">
          <p>Please enter the details of your job below. Feel free to leave the page and come back later, your browser will automatically save your progress.</p>
        </div>


        <div className="form-and-buttons">

          <div className="shortlist-job-form">

            <div className="short-fields">
              <div className="job-title-container">
                <JobFormField name="job-title" label="Job*" value={jobTitle} storeName='job-title'
                onChangeFunction={onTextInputChange} updateStateFunction={updateJobTitle}/>
              </div>
              <JobFormField name="name" label="Name*" value={name} storeName='name'
                onChangeFunction={onTextInputChange} updateStateFunction={updateName} />
              <JobFormField name="company" label="Company" value={company} storeName='company'
                onChangeFunction={onTextInputChange} updateStateFunction={updateCompany} />
            </div>

            <div className="job-form-field email-input">
              <h3>Email*</h3>
              <ReactMultiEmail
                  placeholder="Recipient Emails"
                  label="Recipient Emails"
                  emails={emails}
                  onChange={(newEmailList) => onListChange(newEmailList, updateEmails, 'emails')}
                  validateEmail={email => {
                    return isEmail(email)
                  }}
                  getLabel={(
                    email,
                    index,
                    removeEmail
                  ) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span data-tag-handle onClick={() => removeEmail(index)}>
                          ×
                        </span>
                      </div>
                    );
                  }}
              />
            </div>

            <div className="job-form-field tags-input">
              <h3>Roles*</h3>
              <label htmlFor="roles-tag-input"><p>Type the name of a role and then press Enter</p>
              <TagsInput value={roles} onChange={(newRoleList) => onListChange(newRoleList, updateRoles, 'roles', updateRoleSelectKey)}
              inputProps={{
                id: 'roles-tag-input',
                className: 'react-tagsinput-input',
                placeholder: 'Enter a role'
              }} />
              </label>
            </div>
            

            <div className="message-field job-form-field">
              <label htmlFor="message">Message*</label>
              <textarea id="message" placeholder="Message to shortlist recipient..." name="paragraph_text" cols="50" rows="10"
                  onChange={(updatedMessage) => onTextInputChange(updatedMessage, updateMessage, 'message')} defaultValue={message} >
              </textarea>
            </div>

            {/* Check isBrowser to avoid re-render error on production environment */}
            {isBrowser && 
              <Recaptcha
                sitekey = {process.env.GATSBY_RECAPTCHA_SITE_KEY}
                verifyCallback = {() => updateVerified(true)}
                expiredCallback = {() => updateVerified(false)}
              />
            }

          </div>


          <div className="submit-and-clear">
            <SubmitShortlist shortlist={shortlist} allImagesData={imagesData}
              message={message} emails={emails} jobTitle={jobTitle} submitterName={name} 
              company={company} roles={roles} footerLogo={footerLogo} verified={verified} />

            <button className="button" onClick={() => clearShortlist(updateShortlist, updateRoles, updateEmails,
                                                              updateMessage, updateJobTitle, updateName, updateCompany)}>
              <FaRegTimesCircle size={20} />
              Clear All
            </button>
          </div>

        </div>
        

        <div className="shortlist-container">
          {
            shortlist.map((contact) => 
            <div className="shortlist-profile-container" key={contact.firstname + '-' + contact.lastname}>
              <div className="shortlist-profile-tile">
                <ProfileTile contactData={contact} tileImage={getTileImageFromAllImagesData(imagesData, contact.fullname)}
                updateShortlist={updateShortlist} key={`${getSlugFromContact(contact)}-profile-tile`} />

                { contact.info.closestCentre &&
                    <h4 className="shortlist-main-centres">{contact.info.closestCentre.split(';').join(', ')}</h4>  }

                { getAgeFromDateOfBirth(contact.dateOfBirth) <= maxAgeToDisplay &&
                    <h4 className="shortlist-age">{getAgeFromDateOfBirth(contact.dateOfBirth)}</h4> }

                <MultiSelect
                  key={'role-multiselect-' + roleSelectKey}
                  options={convertArrayToMultiSelectObject(roles)}
                  singleSelect={true}
                  placeholder={getStoredContactRole(contact)} //Placeholder is used to display current Role to avoid React infinite loop
                  onChange={(updatedRole) => onContactRoleChange(updatedRole, getSlugFromContact(contact), updateLastContactRole, updateShortlist)}
                />

                <textarea id={getSlugFromContact(contact)} 
                  key={'textarea-' + roleSelectKey}
                  placeholder="Notes..." 
                  onChange={(updatedNotes) => addContactNotesToStore(updatedNotes.target.value, getSlugFromContact(contact))}
                  name="paragraph_text" cols="50" rows="10" defaultValue={getContactNotesFromStore(getSlugFromContact(contact))}>
                  </textarea>
              </div>
            </div>
            )
          }
        </div>

        {atLeastOneContactHasAvailableRole() && 
          <RoleList roles={roles} key={'role-list-' + lastContactRole} />
        }

      </div>
      
    </Layout>
  )
}

export default Shortlist

export const query = graphql`
  query {
    footer: prismicHeaderFooter {
      data {
        footer_logo {
          alt
          url
        }
      }
    }
  }
`

// export const query = graphql`
//   query {
//     images: allS3Object {
//       nodes {
//         Key
//         localFile  {
//           childImageSharp {
//             gatsbyImageData(transformOptions: {fit: COVER, cropFocus: ATTENTION})
//             resize {
//               src
//             }
//           }
//         }
//       }
//     }
//     footer: prismicHeaderFooter {
//       data {
//         footer_logo {
//           alt
//           url
//         }
//       }
//     }
//   }
// `