import { getAgeFromDateOfBirth, getFullNameFromFirstAndLastNames, getImageDataFromAllImagesData, removeWhitespaceFromString } from "./helpers"
import { filterShortlistByRole } from "./shortlist_helpers"
import { sendEmailAWS } from "./email"


export const sendEmail = (currentShortlist, allImagesData, jobTitle, message,
    submitterName, company, roles, emails, footerLogo, updateEmailSubmitted) => {
      const emailSubject = buildEmailSubject(jobTitle)
      const emailBody = buildEmailHtml(currentShortlist, allImagesData, emailSubject, message, submitterName, company, roles, emails, footerLogo)

      const monarchEmailAddress = 'info@monarchpeople.co.nz'
      // const monarchEmailAddress = 'levipatel@gmail.com' //** For testing */
      const emailsPlusMonarchAddress = [...emails, monarchEmailAddress]

      const emailData = {
        senderAddress: monarchEmailAddress,
        recipientAddresses: emailsPlusMonarchAddress,
        subject: emailSubject,
        body: emailBody,
      }
  
      sendEmailAWS(emailData, updateEmailSubmitted) //Send email
  }
    
    
export const buildEmailHtml = (currentShortlist, allImagesData, emailSubject, message,
    submitterName, company, roles, emails, footerLogo) => {

    let htmlEmailBody = `<h2>${emailSubject}</h2>`
  
    const dateToday = new Date().toLocaleDateString()
    htmlEmailBody += `<h4>${submitterName}`
    if (company) {
      htmlEmailBody += ` - ${company}`
    }
    htmlEmailBody += ` - ${dateToday}</h4>`
    htmlEmailBody += `<p style="max-width: 700px;">Recipients: ${emails.join(', ')}</p>`

    //Message
    htmlEmailBody += `<div style="white-space: pre-line">` //preserve line breaks
    htmlEmailBody += `<p style="max-width: 700px;">${message}</p>`
    htmlEmailBody += `</div>`
  
  
    //Roles with grids of people
    htmlEmailBody += getStyledHorizontalLine()
  
    for (const role of roles) {
      htmlEmailBody += buildSingleRoleHtml(role, currentShortlist, allImagesData)
    }
  
    htmlEmailBody += getStyledHorizontalLine()
  
    let roleList = buildRoleListHtml(roles, currentShortlist)
    htmlEmailBody += roleList
  
    htmlEmailBody += getStyledHorizontalLine()
    htmlEmailBody += buildEmailSignature(footerLogo)
  
    return htmlEmailBody
  }
  
  const buildEmailSubject = (jobTitle) => {
    if (jobTitle) {
      return jobTitle
    }
    else {
      return 'Shortlist'
    }
  }
  
  const buildSingleRoleHtml = (role, currentShortlist, allImagesData) => {
    let shortlistedContactsInRole = filterShortlistByRole(currentShortlist, role)
  
    if (shortlistedContactsInRole.length === 0) { //If there are no contacts in that role don't include it
      return ''
    }
  
    let roleHtml = `<div class="role-section">`
    roleHtml += `<h2 style="margin-bottom: 0;">Role: ${role}</h2>`
  
    //Build a table for each row
    let roleTable = `<table><tbody>`
    let currentTableIndex = 0
    const contactsPerTableRow = 3
    
    for (const contact of shortlistedContactsInRole) {
      if (currentTableIndex === 0) { //First row in the table
        roleTable += `<tr>`
      }
      else if (currentTableIndex % contactsPerTableRow === 0) {
        roleTable += `</tr><tr>`
      }
  
      roleTable += `<td valign="top" style="
        padding-right: 15px;
        padding-bottom: 15px;
        max-width:216px;
      " >` //Cell for this contact
  
      const contactSlug = contact.fields.slug
      const profileUrl = "/" + contactSlug
      const fullnameWithoutSpaces = removeWhitespaceFromString(contact.fullname)
      
      let domain = "https://monarchpeople.com"
      if (typeof window !== `undefined`){ 
        domain = window.location.origin
      }
  
      //Name
      const fullname = contact.fullname
      roleTable += `<h3>${fullname}</h3>`
  
      //Image
      const tileImage = getImageDataFromAllImagesData(allImagesData, fullnameWithoutSpaces)
      const tileImageSmallSource = tileImage.childImageSharp.resize.src 
      roleTable += `<a href="${domain}${profileUrl}">
          <img src="${domain}${tileImageSmallSource}" style="height: 300px; width: 200px; object-fit: cover;" />
        </a>`
  
      //Details
      if (contact.info.closestCentre) {
        roleTable += `<p>${contact.info.closestCentre.split(';').join(', ')}</p>`
      }

      //Age
      const contactAge = getAgeFromDateOfBirth(contact.dateOfBirth)
      const maxAgeToDisplay = 18
      if (contactAge <= maxAgeToDisplay) {
        roleTable += `<p>${contactAge}</p>`
      }
  
      //Notes
      const notes = document.getElementById(contactSlug).value
      roleTable += `<p style="max-width: 300px;">${notes}</p>`
  
      roleTable += `</td>`
      currentTableIndex++
    }
    roleTable += `</tr>`
  
    roleTable += `</tbody></table>`
    roleHtml += roleTable
  
    roleHtml += '</div>'
  
    return roleHtml
  }
  
  const buildRoleListHtml = (roles, currentShortlist) => {
    //Role text list
    let roleListHtml = `<div style="padding: 6px 0;">`
  
    for (const role of roles) {
      let shortlistedContactsInRole = filterShortlistByRole(currentShortlist, role)
  
      if (shortlistedContactsInRole.length > 0) { //If there are no contacts in that role don't include it
        roleListHtml += `<h3 style="margin-bottom: 5px;">Role: ${role}</h3>`
        roleListHtml += `<ul style="list-style: none; padding: 0; margin-top: 0;" >`
        for (const contactInRole of shortlistedContactsInRole) {
          const fullname = getFullNameFromFirstAndLastNames(contactInRole.firstname, contactInRole.lastname)
          roleListHtml += `<li>${fullname}</li>`
        }
        roleListHtml += `</ul>`
      }
  
    }
    roleListHtml += `</div>`
  
    return roleListHtml
  }
  
  
  const buildEmailSignature = (footerLogo) => {
    let signature = `<table style="padding-top: 10px;"><tbody><tr>`
  
    //Logo
    signature += `<td valign="middle" style="padding-right: 10px;">`
    signature += `<a href="${window.location.origin}">
        <img src="${footerLogo.url}" alt="${footerLogo.alt}" style="
          height: 100px;
          border-radius: 2px;
        "/>
      </a>`
    signature += `</td>`
  
    //Contact details
    signature += `<td valign="middle">`
    signature += `<p>Monarch People Ltd</p>`
    signature += `<p><a href="tel:+64-21-866-778" style="text-decoration: none; color: black;">+64 21 866 778</a></p>`
    signature += `<p><a href="${window.location.origin}" style="text-decoration: none; color: black;">${window.location.hostname}</a></p>`
    signature += `</td>`
  
    signature += `</tr></tbody></table>`
    return signature
  }

  const getStyledHorizontalLine = () => {
    return `<hr style="
      border: 0;
      height: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);" 
    />`
  }