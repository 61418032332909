import { filterShortlistByRole } from "./shortlist_helpers"

const hubspotAWSApiGatewayUrl = 'https://e0otf6za43.execute-api.ap-southeast-2.amazonaws.com/default/create-hubspot-deal'

export const createDealWithHubspotAPI = (dealData, updateHubspotSubmitted) => {

  updateHubspotSubmitted("PENDING")

  try {
    fetch(hubspotAWSApiGatewayUrl, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      body: JSON.stringify(dealData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Api-Key': process.env.GATSBY_AWS_HUBSPOT_API_GATEWAY_KEY
      },
    }).then(response => response.json())
    .then(data => {
      if (data.statusCode === 200) {
        updateHubspotSubmitted("SUCCESS")
      }
      else {
        updateHubspotSubmitted("ERROR")
        console.log("Hubspot API Error. data:")
        console.log(data)
      }
    });
  } catch (error) {
    // handle server errors
    console.log(error)
    updateHubspotSubmitted("ERROR") //unsuccessful hubspot submission
  }
};


const buildHtmlContactSummaries = (shortlist, roles) => {
  let html = ''

  for (const role of roles) {
    const contactsInRole = filterShortlistByRole(shortlist, role)

    if (contactsInRole.length === 0) {
      continue //skip this role
    }

    html += `<h3>Role: ${role}</h3>`

    //Each contact assigned the current Role
    for (const contact of contactsInRole) {
      html += `<h5 style="margin-bottom: 4px;">${contact.fullname}</h5>`
  
      if (contact.notes) {
        html += `<p>Notes: ${contact.notes}</p>`
      }
  
      html += `<br />`
    }

  }

  return html
}


export const submitShortlistToHubspot = (shortlist, jobTitle, company, message, roles, emails, updateHubspotSubmitted) => {
    let contactIds = []
    for (const contact of shortlist) {
        contactIds.push(contact.id)
    }

    //Summary of contacts in each role, along with their notes
    const contactSummaryHtml = buildHtmlContactSummaries(shortlist, roles)

    const dealData = {
        jobTitle: jobTitle,
        company: company,
        message: message,
        contactSummaryHtml: contactSummaryHtml,
        emails: emails,
        contactIds: contactIds,
    }

    createDealWithHubspotAPI(dealData, updateHubspotSubmitted)
}
