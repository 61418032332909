
const sendEmailGatewayUrl = 'https://83lsie1uxk.execute-api.ap-southeast-2.amazonaws.com/default/email-monarch/'

export const sendEmailAWS = async (emailBody, updateEmailSubmitted) => {

  // const testMissingInputBody = {
  //   "senderAddress": "info@monarchpeople.co.nz",
  //   "recipientAddresses": ["levipatel@gmail.com", "levi@levipatel.com"],
  //   "subject": "Email Subject for the Job"
  // }

  // const testWorkingBody = {
  //   "senderAddress": "info@monarchpeople.co.nz",
  //   "recipientAddresses": [
  //     "levipatel@gmail.com",
  //     "levi@levipatel.com"
  //   ],
  //   "subject": "Email Subject for the Job",
  //   "body": "<h1>Test Working Body</h1>"
  // }

  try {
    updateEmailSubmitted("PENDING")

    await fetch(sendEmailGatewayUrl, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      body: JSON.stringify(emailBody),
      // body: JSON.stringify(testWorkingBody),
      // body: JSON.stringify(testMissingInputBody),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Api-Key': process.env.GATSBY_AWS_MONARCH_EMAIL_KEY
      },
    }).then(response => response.json())
    .then(data => {
      if (data.statusCode === 200) {
        updateEmailSubmitted("SUCCESS")
      }
      else {
        updateEmailSubmitted("ERROR")
      }
    });
    
  } catch (error) {
    updateEmailSubmitted("ERROR")
  }
}

