import React from "react"

export default function EmailStatus(props) {
    let emailStatus = props.emailStatus

    //** Can handle hubspot status errors if it would be helpful
    // const hubspotStatus = props.hubspotStatus

    if (emailStatus === "SUCCESS") {
        return (
            <div className="email-status success">
                <h4>Thank you for your email!</h4>
            </div>
        )
    }
    else if (emailStatus === "ERROR") {
        return (
            <div className="email-status error">
                <h4>Sorry, an error occurred with your submission.</h4>
                <p>Please try again or contact us to discuss your project.</p>
            </div>
        )
    }
    else {
        return null
    }

}